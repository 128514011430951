<template>
  <div>
    <div class="main-title">VIP用户列表</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0=""></ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l">
          <el-select v-model="searchType" clearable slot="prepend" placeholder="选择类型" size="mini">
            <el-option label="微信昵称" :value="1"></el-option>
            <el-option label="UID" :value="2"></el-option>
          </el-select>
          <el-input class="margin-l-sm" v-model="userName" size="mini" style="width: 160px" clearable>
          </el-input>
        </span>

        <span class="margin-l-sm fl-l">
          <el-select v-model="searchData.module" clearable placeholder="选择分类" size="small">
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-select v-model="searchData.status" clearable placeholder="状态" size="small">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-date-picker size="small" v-model="value2" type="daterange" align="right" unlink-panels range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </span>

        <span class="margin-l-sm fl-l">
          <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
        </span>

        <span class="fl-r"> </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content" v-loading="loading">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="id" label="id"> </el-table-column>
          <el-table-column prop="user_id" label="uid"> </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>

          <el-table-column label="头像" width="180">
            <template slot-scope="scope">
              <el-avatar shape="square" :size="50" :src="scope.row.avatar_url" icon="el-icon-user-solid">
              </el-avatar>
            </template>
          </el-table-column>

          <el-table-column prop="module_name" label="会员类型">
          </el-table-column>

           <el-table-column label="状态">
            <template slot-scope="scope">
                <el-tag type="danger" v-if="scope.row.deleted_at">已删除</el-tag>
                <el-tag v-else>正常</el-tag>
            </template>
          </el-table-column> 

          <el-table-column prop="expire_at" label="过期时间"> </el-table-column>

          <el-table-column prop="created_at" label="创建时间">
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
               <span v-if="!scope.row.deleted_at">
                  <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="会员关闭不可恢复,确定关闭吗？"
                  @confirm="closeUserVip(scope.row.id)"
                  >
                  <el-link slot="reference" type="primary">关闭</el-link>
                  </el-popconfirm>
               </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize" @current-change="setPage"
            :total="total">
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "list",
  data() {
    return {
      searchData: {
        module: "",
        status: ""
      },
      loading: false,
      tableData: [],
      options: [
        {
          id: 1,
          name: "听书会员",
        },
      ],
      statusOptions: [
        {
          label: "正常",
          value: 1
        },
        {
          label: "已删除",
          value: 0
        }
      ],
      searchType: null,
      userName: null,
      value2: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    ...mapActions("user", ["getUserVipList","closeVip"]),
    ...mapActions("common", ["vipSelectList"]),
    search() {
      this.searchData.datePick = this.value2;
      if (this.searchType == 1) this.searchData.nickName = this.userName;
      else this.searchData.userId = this.userName ?? 0;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let form = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      }
      const { data } = await this.getUserVipList(form);
      const res = await this.vipSelectList();
      this.options = res.data;
      this.total = data.total;
      this.tableData = data.list;
      this.loading = false;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    async closeUserVip(id){
      const { ret } = await this.closeVip(id);
      if(ret === 0) {
        this.$message.success("关闭成功");
        this.getList();
      }else{
        this.$message.error("关闭失败");
      }
    }
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
.el-popover {
  min-width: 80px;
}
</style>
